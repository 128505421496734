.home-page-section {
    padding-top: 51.3px;
    text-align: center;
    position: relative;
}

.icon-pin {
    position: absolute;
    top: -42px;
    width: 56px;
    height: 90px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}

.wrap-home-title {
    padding-bottom: 3px;
}

.section-title {
    text-align: center;
    color: $tundora;
    font-family: $Avenir-Black;
    margin-bottom: 12px;
}

.section-text {
    text-align: center;
    padding: 0 25%;
    font-family: $Avenir-Roman;
    line-height: 1.44;
    color: $boulder;
}

.home-wrapper {
    margin-top: 73px;
    margin-bottom: 80px;
    &:last-child {
        margin-bottom: 0;
    }
}

.row.is-flex {
    display: flex;
    flex-wrap: wrap;
    &:before,
    &:after {
        width: 0;
    }
}

.row.is-flex>[class*='col-'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-wrap-text {
    text-align: left;
    .wrap-text-name {
        margin-bottom: 20px;
    }
    .wrap-text-description {
        margin-bottom: 30px;
    }
}

.small {
    width: 80%;
}

@media screen and (max-width: 767px) {
    .wrap-home-title {
        padding-bottom: 3px;
    }
    .home-page-section {
        padding-top: 25px;
        .section-title {
            margin-top: 11.2px;
            margin-bottom: 15px;
            font-size: 28px;
            padding: 0 8%;
        }
        .icon-pin {
            top: -38px;
            max-width: 44px;
            max-height: 70px;
            object-fit: contain;
        }
        .row.is-flex {
            display: block;
        }
        .section-text {
            padding: 0;
            text-align: center;
        }
    }
    .wrap-text-name {
        display: none;
        margin-top: 0;
        text-align: center;
    }
    .wrap-text-name-none {
        margin-top: 0;
        text-align: center;
        display: block;
        font-size: 24px;
        padding: 0 8%;
    }
    .home-wrapper {
        margin-top: 52px;
        margin-bottom: 0;
        .wrap-text-description {
            text-align: center;
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .indent {
            text-indent: 1em
        }
        .home-wrap-img {
            margin-top: 20px;
            margin-bottom: 15px;
        }
    }
    .small {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .wrap-text-name {
        display: block;
        margin-top: 0;
        text-align: left;
    }
    .wrap-text-name-none {
        margin-top: 0;
        text-align: left;
        display: none;
    }
}