.general-content {
  padding: 0 15px;
  @media screen and (min-width: 768px) {
    max-width: 750px;
  }
  margin-bottom: 140px;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  h4 {
    font-size: 22px;
  }
  h5 {
    font-size: 19px;
  }
  h6 {
    font-size: 15px;
  }

  img.float-left, img.float-right {
    width: 30%;
    height: auto;
  }
  img.float-left {
    float: left;
    margin-right: 20px;
  }
  img.float-right {
    float: right;
    margin-left: 20px;
  }

  .system-title {
    margin-top: 50px;
    margin-bottom: 40px;
    text-align: center;
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }

  .general-paragraph {
    text-align: left;
    margin-top: 40px;
    margin-bottom: 40px;
    div {
      margin: 10px 0;
    }
    ul, ol {
      margin: 20px 0;
      padding-left: 25px;
      li {
        padding-left: 10px;
      }
    }
  }

  a {
    font-weight: bolder;
    color: $cerulean;
  }

  u {
    text-decoration: none;
    box-shadow: inset 0 -1px 0 white, inset 0 -3px 0 $light-gray;
  }

  blockquote {
    border-left: none;
    padding-left: 35px;
    font-style: italic;
    .quote-symbol {
      position: absolute;
      margin-left: -38px;
      margin-top: 4px;
      width: 27px;
    }
  }
  .general-paragraph .ol-h2 {
    list-style: none;
    padding-left: 0;
    & > li {
      padding-left: 0;
      & > ol {
        padding-left: 40px;
      }
    }
    ol.lower-alpha {
      list-style-type: lower-alpha;
    }
  }
  ul.no-style {
    list-style-type:none;
  }
  .disclaimer-table {
    tr {
      td, th {
        font-family: $Avenir-Heavy, sans-serif;
      }
    }
    @media screen and (max-width: 767px) {
      .mobile-hidden {
        display: none;
      }
      td, th {
        max-width: none;
        width: 100%;
        display: block;
        margin: 10px 0;
        .desktop-hidden.title {
          display: block;
          font-size: 18px;
          font-family: $Avenir-Roman, sans-serif;
          color: $boulder;
        }
        .fas {
          float: left;
          margin-right: 10px;
        }
        &:first-of-type {
          font-size: 22px;
          font-family: $Avenir-Heavy, sans-serif;
          color: $tundora;
          margin-top: 20px;
        }
        &:last-of-type {
          padding-bottom: 20px;
          border-bottom: solid 1px #DCDCDC;
        }
      }
      tr:last-of-type td {
        border-bottom: none;
      }
    }
    @media screen and (min-width: 768px) {
      .desktop-hidden {
        display: none;
      }
      td, th {
        border: solid 1px #DCDCDC;
        text-align: center;
        padding: 6px 10px;
        font-size: 14px;
        &:first-of-type {
          text-align: left;
        }
      }
    }
    @media screen and (min-width: 1220px) {
      min-width: 1200px;
      margin-left: -240px;
    }
  }
}


@media screen and (max-width: 767px) {
  .general-content {
    margin-bottom: 60px;
    img.float-left, img.float-right {
      float: none;
      margin: 0px;
      width: 50%;
    }
  }
}
