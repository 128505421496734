.system-section {
    background-color: $solitude;
    .system-title {
        margin-top: 62px;
        margin-bottom: 12px;
        text-align: center;
        color: $tundora;
        font-family: $Avenir-Black;
    }
    .system-text {
        text-align: center;
        margin-bottom: 56px;
        padding: 0 25%;
        font-family: $Avenir-Roman;
        line-height: 1.44;
        color: $boulder;
    }
}

.wrap-system-block {
    text-align: left;
    .system-image {
        max-height: 120px;
        width: auto;
        margin-bottom: 35px;
    }
    .system-info-list {
        margin-bottom: 80px;
        .system-info-title {
            font-size: 20px;
            font-family: $Avenir-Black;
            margin-bottom: 11px;
            color: $scorpion;
        }
    }
    .system-list {
        list-style-type: none;
        padding-left: 4px;
        .list-options {
            text-align: left;
            margin-bottom: 4px;
            &::before {
                content: url("../images/icon-tick.svg");
                float: left;
                padding-right: 10.8px;
            }
        }
    }
    .icon-question {
        padding-left: 7px;
        width: 20px;
        height: 20px;
    }
}

.infographic-image {
    margin-bottom: 125px;
}

@media screen and (max-width: 767px) {
    .system-section {
        .system-title {
            margin-top: 38px;
            margin-bottom: 15px;
            font-size: 28px;
        }
        .system-text {
            margin-bottom: 20px;
            padding: 0;
            text-align: center;
        }
    }
    .wrap-system-block {
        text-align: center;
        .system-list {
            .list-options {
                text-align: center;
                &::before {
                    float: none;
                }
            }
        }
        .system-image {
            margin-bottom: 20px;
        }
        .system-info-list {
            margin-bottom: 40px;
        }
    }
    .infographic-image {
        margin-top: 0;
        margin-bottom: 47px;
        padding: 0 3%;
    }
    .project-image {
        padding: 0 1%;
    }
}
