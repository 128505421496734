.main-wrapper {
    //background-image: linear-gradient($solitude, $white);
}

.gradient-wrapper {
  background-image: linear-gradient($solitude, $white);
}

.header {
    width: 100%;
    position: absolute;
    top: 0;
    font-size: 0;
    padding: 10px 0;
    background: transparent;
    z-index: 1000;
}

.logo-link {
    display: inline-block;
    padding: 12px 15px;
    vertical-align: top;
    .header-logo {
        width: 142px;
        height: 24px;
    }
}

.navbar {
    margin-bottom: 0;
    border: none;
}

.navbar-btn {
    float: right;
    border: none;
    .icon-bar {
        width: 26px;
        height: 3px;
        border-radius: 5px;
        background-color: $cerulean;
        ;
    }
}

.navbar-menu-wrap {
    text-align: right;
}

.navbar-menu {
    display: inline-block;
    font-family: $Avenir-Black;
    font-size: 18px;
    float: none;
    .navbar-menu-item {
        .navbar-menu-divider {
            border-left: 2px solid $venice-blue;
            padding-left: 11px;
            outline: none;
            &:hover {
                color: $picton-blue;
            }
            &:focus {
                color: $picton-blue;
            }
        }
        .navbar-menu-link {
            padding-right: 9px;
            padding-left: 0;
            font-size: 14px;
            font-family: $Avenir-Black;
            line-height: 1.86;
            letter-spacing: 0.6px;
            color: $curious-blue;
            text-transform: uppercase;
            text-decoration: none;
            outline: none;
            &:hover {
                color: $picton-blue;
            }
            &:focus {
                color: $picton-blue;
            }
            &:hover,
            &:active,
            &:visited,
            &:link {
                background-color: transparent;
            }
        }
        .user-icon {
            margin-top: -5px;
            width: 14px;
        }
        .btn-try-now {
            margin-right: 20px;
            margin-top: 15px;
            padding-bottom: 15px;
            width: 87px;
            height: 26px;
            border-radius: 3px;
            background-color: $cerulean;
            font-size: 14px;
            font-family: $Avenir-Black;
            text-align: center;
            color: $white;
            padding: 4px;
            outline: none;
            &:hover {
                background-color: $picton-blue;
            }
            &:focus {
                background-color: $picton-blue;
            }
        }
    }
}

.hero-section {
    overflow: hidden;
    .hero-wrapper {
        position: relative;
        width: 200%;
        margin-left: -50%;
        border-bottom-left-radius: 50% 30%;
        border-bottom-right-radius: 50% 30%;
        overflow: hidden;
        .hero-image-wrapper {
            width: 50%;
            margin-left: 25%;
            background-repeat: no-repeat;
            background-size: 100% auto;
            overflow: hidden;
        }
        .home-page-hero, .home-page-hero-simple {
            height: 58vw;
            max-height: 538px;
            background-image: url("../images/image-home-hero.png");
            background-repeat: no-repeat;
            background-position: center bottom;
        }
        .home-page-hero-simple {
            height: 15vw;
        }
    }
}

.hero-content {
    width: 50%;
    margin-left: 25%;
    position: absolute;
    top: 36%;
    text-align: center;
    font-family: $Avenir-Black;
    .tda-logo {
        width: 338px;
    }
    .hero-title {
        font-size: 40px;
        margin-top: 57px;
        color: $white;
        text-transform: none;
    }
    .hero-wrapper-btn {
        margin-top: 66px;
        text-align: center;
        .btn-hero {
            text-decoration: none;
            display: inline-block;
            width: 290px;
            height: 56px;
            border-radius: 6px;
            background-color: $sorbus;
            line-height: 56px;
            outline: none;
            &:hover {
                background-color: $neon-carrot;
            }
            &:focus {
                background-color: $neon-carrot;
            }
            .btn-hero-name {
                position: relative;
                font-size: 20px;
                color: $white;
                padding-right: 30px;
                &:after {
                    position: absolute;
                    right: 0;
                    top: -19px;
                    content: url(../images/icon-arrow-white-down.svg);
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
}

.home-hero-content {
    top: 80px!important;
}

@media screen and (max-width: 767px) {
    .header {
        background-image: url("../images/image-home-hero.png");
    }
    #navbar {
        text-align: center;
    }
    .navbar-toggle {
        padding: 7px 5px;
        margin-bottom: 12px;
        margin-right: 0;
    }
    .navbar-menu-wrap .navbar-menu .navbar-menu-item {
        padding: 15px 20px;
        text-align: left;
        .user-icon {
            margin-top: -5px;
        }
        .navbar-menu-link {
            padding: 0;
            margin: 0;
            font-size: 18px;
            height: 26px;
        }
    }
    .navbar-menu {
        .navbar-menu-item {
            padding: 15px 20px;
            .navbar-menu-divider {
                border: none;
                padding-left: 0;
            }
            .btn-try-now {
                margin: 0;
                padding: 0;
                height: 26px;
                width: 100%;
                background-color: transparent;
                font-size: 18px;
                font-family: $Avenir-Black;
                letter-spacing: 0.6px;
                color: $curious-blue;
                text-transform: uppercase;
                line-height: 26px;
            }
        }
    }
    .hero-content {
        .tda-logo {
            padding-top: 13px;
            width: 173px;
            height: auto;
            position: absolute;
            margin-top: -80px;
            left: 20px;
            z-index: 1000;
        }
        .hero-wrapper-btn {
            margin-top: 33px;
            .btn-hero {
                width: 70%;
                max-width: 295px;
                height: 46px;
                line-height: 46px;
                .btn-hero-name {
                    font-size: 15px;
                    &:after {
                        top: -16px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .hero-section {
      .hero-wrapper {
          .home-page-hero-simple {
              min-height: 200px;
          }
      }
  }
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
  .hero-section {
      .hero-wrapper {
          .home-page-hero-simple {
              min-height: 200px;
          }
      }
  }
}


@media only screen and (min-width: 320px) and (max-width: 480px) {
    .hero-section {
        .hero-wrapper {
            .hero-image-wrapper {
                background-size: auto;
            }
            .home-page-hero {
                height: auto;
                min-height: 329px;
            }
        }
    }
    .hero-content {
        .hero-title {
            margin-top: 12px;
            font-size: 30px;
            line-height: 1.4;
        }
        .hero-wrapper-btn {
            margin-top: 33px;
            .btn-hero {
                width: 100%;
                max-width: 295px;
                height: 46px;
                line-height: 46px;
                .btn-hero-name {
                    font-size: 15px;
                    &:after {
                        top: -16px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 481px) and (max-width: 767px) {
    .hero-section {
        .hero-wrapper {
            .hero-image-wrapper {
                background-size: auto;
            }
            .home-page-hero {
                height: auto;
                min-height: 280px;
            }
            .home-page-hero-simple {
                background: none;
            }
        }
    }
    .hero-content {
        .hero-title {
            margin-top: 12px;
            font-size: 30px;
        }
    }
}


@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
    .user-icon {
        width: auto !important;
    }
}
