/*
	*** COLOR VARIABLES ***
*/


/*
	assignment of variables to colors
*/

$white: #FFFFFF;
$curious-blue: #19ADE8;
$prussian-blue: #00395A;
$tundora: #4A4A4A;
$sorbus: #FB870B;
$boulder: #7A7777;
$solitude: #E9F7FF;
$cerulean: #009CDB;
$scorpion: #615B5B;
$periwinkle: #ACC3E0;
$neon-carrot: #FD9E38;
$picton-blue: #24B6F1;
$venice-blue: #055c7f;
$light-gray: #b3b3b3;
$light-blue: #82bee5;
