@font-face {
    font-family: 'Avenir-Black';
    src: url('../fonts/Avenir-Black.eot');
    src: url('../fonts/Avenir-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/Avenir-Black.woff2') format('woff2'), url('../fonts/Avenir-Black.woff') format('woff'), url('../fonts/Avenir-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Roman';
    src: url('../fonts/Avenir-Roman.eot');
    src: url('../fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'), url('../fonts/Avenir-Roman.woff2') format('woff2'), url('../fonts/Avenir-Roman.woff') format('woff'), url('../fonts/Avenir-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Heavy';
    src: url('../fonts/Avenir-Heavy.eot');
    src: url('../fonts/Avenir-Heavy.eot?#iefix') format('embedded-opentype'), url('../fonts/Avenir-Heavy.woff2') format('woff2'), url('../fonts/Avenir-Heavy.woff') format('woff'), url('../fonts/Avenir-Heavy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

$Avenir-Black: "Avenir-Black";
$Avenir-Roman: "Avenir-Roman";
$Avenir-Heavy: "Avenir-Heavy";

$font-size-large: 26px;
$font-size-medium: 16px;
$font-size-small: 10px;
