.price-section {
    //margin-top: 113px;
    padding-top: 50px;
    padding-bottom: 90px;
    @media screen and (max-width: 767px) {
      padding-bottom: 120px;
    }
    background-color: $solitude;
    //background-image: url("../images/pattern-divider-01.svg");
    //background-repeat: repeat-x;
    //background-position: bottom;
    .price-title {
        text-align: center;
        color: $tundora;
        font-family: $Avenir-Black;
    }
    .price-text {
        text-align: center;
        margin-top: 12px;
        margin-bottom: 45px;
        padding: 0 25%;
        font-family: $Avenir-Roman;
        line-height: 1.44;
        color: $boulder;
    }
}

.wrap-product-price {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    margin-bottom: 60px;
    .prod-img-wrap {
        margin-bottom: 14px;
        .product-image {
            max-height: 180px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
            position: relative;
            height: 180px;
            .product-image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-height: 100%;
            }
        }
    }
    .product-title {
        line-height: 38px;
        margin-bottom: 9px;
        color: $tundora;
    }
    .product-description {
        margin-bottom: 16px;
        font-family: $Avenir-Heavy;
        font-size: $font-size-medium;
        min-height: 92px;
    }
    .product-text {
        margin-bottom: 13px;
        font-size: 16px;
        line-height: 1.19;
        text-align: center;
        color: $boulder;
    }
    .product-valued {
        margin-bottom: 16px;
        font-family: $Avenir-Black;
        color: $tundora;
    }
    .product-button-sorbus {
        width: 100%;
        height: 46px;
        border-radius: 6px;
        background-color: $sorbus;
        font-size: 15px;
        font-family: $Avenir-Black;
        text-align: center;
        color: $white;
        line-height: 32px;
        outline: none;
        &:hover {
            background-color: $neon-carrot;
        }
        &:focus {
            background-color: $neon-carrot;
        }
    }
    .product-button-cerulean {
        width: 100%;
        height: 46px;
        border-radius: 6px;
        background-color: $cerulean;
        font-size: 15px;
        font-family: $Avenir-Black;
        text-align: center;
        color: $white;
        line-height: 32px;
        outline: none;
        &:hover {
            background-color: $picton-blue;
        }
        &:focus {
            background-color: $picton-blue;
        }
    }
}

.prod-info-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.compare-plans {
  margin-top: 30px;
  margin-bottom: 50px;

  .compare-plans-table {
    width: 100%;
    box-sizing: border-box;
    tr {
      vertical-align: top;
      &:nth-child(odd) {
        background-color: $solitude;
      }
      &:nth-child(even) {
        background-color: #F5FBFF;
      }
      :first-child {
        padding-left: 25px;
      }
      :last-child {
        padding-right: 25px;
      }
    }
    th {
      font-size: 22px;
      font-weight: bold !important;
      color: $cerulean;
    }
    .pricing-row {
      font-weight: bolder;
      color: $tundora;
      .subtitle {
        font-weight: lighter;
        color: $boulder;
      }
    }
    .col {
      width: 17%;
      text-align: center;
      padding: 10px 0;
    }
    .main-col {
      width: 32%;
      text-align: left;
      font-weight: bolder;
      color: $tundora;
      .lighter {
        font-weight: lighter;
      }
    }
    .icon {
      height: 23px;
      width: auto;
    }
  }
  .bar {
    font-weight: bold;
    text-align: center;
    color: white;
    &.dark {
      background-color: $prussian-blue;
      padding: 8px 0;
    }
    &.light {
      background-color: $light-blue;
      padding: 8px 0;
    }
  }
}

.compare-plans-mobile {
  select {
    display: block;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    max-width: 295px;
    width: 100%;
    height: 46px;
    border: 2px solid $cerulean;
    border-radius: 6px;
    padding-left: 10px;
    color: $cerulean;
    font-weight: bold;
    // arrow:
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../images/select-background.png") no-repeat 96% 50%;
    background-size: 20px 20px;
  }
  .plan-panel {
    display: none;
    margin: 0 15px;
    .plan-title {
      font-size: 24px;
      margin-bottom: 25px;
    }
    table {
      width: 100%;
      box-sizing: border-box;
      font-size: 15px;
      tr {
        vertical-align: top;
        :first-child {
          padding-left: 15px;
        }
        :last-child {
          padding-right: 15px;
        }
        &:nth-child(odd) {
          background-color: $solitude;
        }
        &:nth-child(even) {
          background-color: #F5FBFF;
        }
      }
      .col {
        width: 50%;
        padding: 7px 0px;
        text-align: center;
      }
      .main-col {
        font-weight: bold;
        text-align: left;
        color: $tundora;
      }
      .icon {
        height: 22px;
        width: auto;
      }
    }
    .bar {
      font-weight: bold;
      text-align: center;
      color: white;
      &.dark {
        background-color: $prussian-blue;
        padding: 8px 0;
      }
      &.light {
        background-color: $light-blue;
        padding: 8px 0;
      }
    }
  }
  #free {
    display: block;
  }
}


@media screen and (max-width: 767px) {
    .price-section {
        //margin-top: 52px;
        padding-top: 52px;
        .row.is-flex {
            display: block;
        }
        .price-title {
            font-size: 28px;
            margin-bottom: 15px;
        }
        .price-text {
            padding: 0;
            text-align: center;
            margin: 0;
            text-indent: 1em;
        }
        .wrap-product-price {
            margin-top: 40px;
            .prod-img-wrap {
                margin-bottom: 12px;
            }
        }
        .product-button-sorbus {
            max-width: 295px;
        }
        .product-button-cerulean {
            max-width: 295px;
        }
        .product-description {
            min-height: 0px;
        }
    }
    .product-text {
        padding: 0 10%;
    }
}

@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
    .is-flex [class*="col-"] {
        .wrap-product-price {
            min-height: 480px;
            .product-image {
                width: auto;
                height: 180px;
            }
        }
        @media only screen and (min-width: 480px) and (max-width: 767px) {
            .wrap-product-price {
                min-height: 400px;
            }
        }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
            .wrap-product-price {
                min-height: 440px;
            }
        }
        @media only screen and (min-width: 992px) {
            .wrap-product-price {
                min-height: 500px;
            }
        }
    }
}
