$background-color: #ffffff;
body {
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-family: $Avenir-Roman, sans-serif;
    line-height: 1.44;
    overflow-x: hidden;
    color: $boulder;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $Avenir-Black, sans-serif;
    color: $tundora;
}

h1 {
    font-size: 40px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h5 {
    font-size: 18px;
    font-family: $Avenir-Heavy, sans-serif;
}

p {
    font-size: 18px;
    margin-bottom: 20px;
}

img {
    width: 100%;
    height: auto;
}

.embed-container {
  position: relative;
  padding-bottom: 43%;
  overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-container {
  position: relative;
  padding-bottom: 52.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.youtube-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home-wrapper {
  .youtube-container {
    border-radius: 25px;
    overflow: hidden;
  }
  .home-wrap-img {
    position: relative;
  }
}

.video-wrapper {
  position: absolute;
  pointer-events: none;
  &.shadow {
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 2;
    border-radius: 27px;
    box-shadow: 2px 2px 5px #797979;
  }
}

.hidden-on-mobile{
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.hidden-on-desktop{
  display: block;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.videos-section {
  background-image: url("../images/pattern-divider-01.svg");
  background-repeat: repeat-x;
  background-position: bottom;
  padding-bottom: 140px;
}
