/*  inport all scss files  */


/*================ SETTINGS ================*/

@import './settings/fonts';
@import './settings/color';

/*================ MODULES ================*/

@import './modules/basic-theme-styles';
@import './modules/header';
@import './modules/footer';
@import './modules/graphs-section';
@import './modules/price-section';
@import './modules/system-sections';
@import './modules/general-content';
@import './modules/modals';
