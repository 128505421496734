.jquery-modal.blocker.current {
  z-index: 999999 !important;
  background-color: rgba(5, 36, 55, 0.90);
}

.modal {
  padding: 0px !important;
  border-radius: 25px !important;
  border-top-left-radius: 29px !important;
  border-top-right-radius: 29px !important;
  text-align: center !important;
  max-width: 800px !important;
  overflow: visible;
  width: 60%;
  background-color: #e8fbff !important;
  z-index: 3 !important;

  .close-modal {
    display: none !important;
  }
  .close-modal-custom {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -40px;
    margin-right: -40px;
    @media screen and (max-width: 767px) {
      margin-top: -20px;
      margin-right: -33px;
    }
    img {
      height: 34px;
      width: auto;
    }
  }

  header {
    max-height: 278px;
    background-image: url("../images/image-home-hero.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    .tda-logo {
      width: 300px;
      height: auto;
      margin-top: 55px;
      margin-bottom: 30px;
    }
    .title {
      color: white;
    }
    .icon {
      width: 50px;
      height: auto;
      margin-top: 44px;
    }
  }

  main {
    margin-top: 60px;
    text-align: center;
    .description {
      margin: 20px 15%;
    }
    form {
      margin-bottom: 60px;
      ul {
        list-style: none;
        li {
          margin-right: 40px;
          margin-bottom: 10px;
        }
      }
      .subfield {
        display: inline !important;
        input {
          display: inline;
          margin-right: 4px;
        }
        .choice {
          font-weight: lighter;
          margin-bottom: 20px;
        }
      }
      .field.text, .submit {
        width: 75%;
        height: 56px;
        border-radius: 10px;
        padding-left: 15px;
        color: black;
      }
      .field.text {
        border: 1px solid white;
        font-weight: bolder;
      }
      .submit {
        background-color: $neon-carrot;
        color: white;
        text-transform: uppercase;
        font-weight: bold;
        border: none;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .modal {
    header {
      background-size: auto;
    }
  }
}

@media screen and (max-width: 507px) {
  .modal {
    header {
      background-size: auto;
      max-height: 240px;
      .tda-logo {
        width: 200px;
      }
      .title {
        font-size: 30px;
      }
    }
    main {
      h2 {
        font-size: 24px;
      }
      .description {
        margin: 20px 5%;
      }
    }
    form {
      .choice {
        display: inline;
      }
      .field.text, .submit {
        width: 95% !important;
      }
    }
  }
}

@media screen and (max-width: 389px) {
  header {
    .icon {
      margin-top: 14px !important;
    }
  }
}
