.footer-section {
    overflow: hidden;
    background-color: $solitude;
    margin-top: -4%;
    .footer-wrapper {
        position: relative;
        width: 200%;
        margin-left: -50%;
        border-top-left-radius: 50% 30%;
        border-top-right-radius: 50% 30%;
        overflow: hidden;
        .footer-image-wrapper {
            height: 34vw;
            width: 50%;
            margin-left: 25%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            overflow: hidden;
        }
        .page-footer {
            height: auto;
            background-image: url("../images/image-footer-bg.png");
            background-repeat: no-repeat;
            background-position: center top;
        }
    }
  &.white {
    background-color: white;
  }
}

.wrap-info {
    text-align: center;
    font-family: $Avenir-Black;
    .footer-title {
        margin-top: 48px;
        color: $white;
        margin-bottom: 1px;
        font-size: $font-size-large;
        &:last-of-type {
          margin-top: 68px;
        }
    }
    .footer-text {
        font-size: 28px;
        color: $white;
    }
    .btn-free {
        margin-top: 30px;
        width: 290px;
        height: 56px;
        border-radius: 6px;
        background-color: $sorbus;
        font-size: 20px;
        text-align: center;
        color: $white;
        &:hover {
            background-color: $neon-carrot;
            outline: none;
        }
        &:focus {
            background-color: $neon-carrot;
            outline: none;
        }
    }
}

.wrapper-tda {
    margin-bottom: 73px;
    .footer-img-tda {
        padding-top: 30px;
        padding-left: 30px;
        max-width: 165px;
        cursor: pointer;
    }
}

.breadcrumb-list {
    margin-top: 65px;
    margin-bottom: 8px;
    list-style-type: none;
    padding: 0;
    line-height: 26px;
    .list-item {
        display: inline-block;
        padding-left: 30px;
        margin: 0;
        cursor: pointer;
        &:first-of-type {
          padding-left: 0px;
        }
        .list-name {
            font-family: $Avenir-Black;
            font-size: 10px;
            line-height: 2.6;
            letter-spacing: 0.4px;
            color: $white;
            text-transform: uppercase;
            text-decoration: none;
            outline: none;
            &:hover {
                color: $periwinkle;
            }
            &:focus {
                color: $periwinkle;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .footer-section {
        margin-top: -6.5%;
        .footer-wrapper {
            .page-footer {
                height: auto;
            }
            .footer-image-wrapper {
                background-size: cover;
            }
        }
    }
    .wrap-info {
        .footer-title {
            margin-top: 40px;
            margin-bottom: 15px;
            font-size: 15px;
            &:last-of-type {
              margin-top: 0px;
            }
        }
        .wrapper-tda {
            margin-bottom: 0;
            .footer-img-tda {
                padding: 0;
                margin-left: 14px;
                margin-top: 25px;
                max-width: 120px;
            }
        }
        .footer-text {
            font-size: 15px;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        .btn-free {
            margin-top: 0;
            max-width: 295px;
            height: 46px;
            font-size: 15px;
        }
        .breadcrumb-list {
            margin-top: 20px;
            margin-bottom: 18px;
            .list-item {
                padding: 0;
                width: 100%;
            }
        }
        .customer-logo {
          width: 120px;
          margin: 5px 5px 20px 5px;
        }
        .footer-copyright {
          font-family: $Avenir-Roman;
          text-transform: uppercase;
          font-size: $font-size-small;
          font-weight: 100;
          color: #bee0f5;
          margin-bottom: 18px;
        }
    }
}

@media only screen and (min-width: 768px) {
    .footer-section {
      margin-top: -5%;
      .footer-wrapper {
        .page-footer {
          height: auto;
        }
        .footer-image-wrapper {
          background-size: cover;
        }
      }
      .customer-logos {
        .customer-logo {
          width: 160px;
          margin: 30px 12px 0px 12px;
        }
      }
      .footer-copyright {
        font-family: $Avenir-Roman;
        text-transform: uppercase;
        font-size: $font-size-small;
        font-weight: 100;
        color: #bee0f5;
        margin-bottom: 18px;
      }
    }
}
